import React, { useState } from 'react';
import { registerUser } from '../services/api';
import { useTranslation } from 'react-i18next';

const Register = () => {
  const { t, i18n } = useTranslation();
  const [selectedRole, setSelectedRole] = useState('advertiser');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    role: selectedRole,
  });
  const [error, setError] = useState('');

  const roles = [
    { value: 'advertiser', label: t('advertiser'), description: t('create_and_manage_flyer_campaigns') },
    { value: 'printer', label: t('printer'), description: t('claim_and_print_flyer_requests') },
    { value: 'promoter', label: t('promoter'), description: t('distribute_flyers_to_the_target_audience') },
  ];

  const handleRoleSelection = (roleValue) => {
    setSelectedRole(roleValue); // Set the internal value
    setFormData({ ...formData, role: roleValue }); // Update form data
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await registerUser(formData);
      console.log('User registered successfully:', result);
      // Redirect or show success message
    } catch (err) {
      console.error('Registration failed:', err);
      setError(err.message || 'Registration failed');
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center">
      <div className="bg-white shadow-md rounded-md p-8 w-full max-w-lg">
        <h1 className="text-3xl font-bold text-center mb-6 text-blue-600">{t('register')}</h1>

        {/* Role Selection */}
        <div className="mb-8">
          <h2 className="text-lg font-medium mb-4">{t('who_are_you')}</h2>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
            {roles.map((item) => (
              <div
                key={item.value}
                onClick={() => handleRoleSelection(item.value)} // Use internal value
                className={`p-4 rounded-md cursor-pointer border-2 ${selectedRole === item.value ? 'border-blue-600 bg-blue-50' : 'border-gray-300'
                  } hover:border-blue-600`}
              >
                <h3 className="text-xl font-semibold text-center mb-2">{item.label}</h3> {/* Display translated text */}
                <p className="text-sm text-center text-gray-600">{item.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Display Errors */}
        {error && <p className="text-red-500 text-sm mt-2">{error}</p>}

        {/* Registration Form */}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
              {t('name')}
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-600 focus:border-blue-600"
            />
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-600 focus:border-blue-600"
            />
          </div>
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-600 focus:border-blue-600"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition"
          >
            {t('register_as_selected_role', { role: roles.find(role => role.value === selectedRole)?.label })}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Register;
