import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from './../assets/logo.png';

const Navbar = ({ isAuthenticated, userRole }) => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <nav className="bg-blue-600 p-4 shadow-lg">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/" className="flex items-center">
          <img src={logo} alt="Jarchi Logo" className="h-8 w-auto mr-2" />
          {/* <span className="text-white text-lg font-bold">Jarchi</span> */}
        </Link>

        <ul className="flex space-x-4 items-center">
          {isAuthenticated ? (
            <>
              <li>
                <Link className="text-white hover:text-gray-200 text-2xl" to="/dashboard">{t('dashboard')}</Link>
              </li>
              <li>
                <Link className="text-white hover:text-gray-200 text-2xl" to="/settings">{t('settings')}</Link>
              </li>
              <li>
                <button
                  className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                  onClick={() => {
                    localStorage.removeItem('token');
                    window.location.reload();
                  }}
                >
                  {t('logout')}
                </button>
              </li>
            </>
          ) : (
            <>
              <li>
                <Link className="text-white hover:text-gray-200 text-2xl" to="/login">{t('login')}</Link>
              </li>
              <li>
                <Link className="text-white hover:text-gray-200 text-2xl" to="/register">{t('register')}</Link>
              </li>
            </>
          )}
          {/* Language Dropdown */}
          <li>
            <select
              className="bg-white text-gray-700 px-2 py-1 rounded focus:outline-none"
              onChange={(e) => changeLanguage(e.target.value)}
              value={i18n.language}
            >
              <option value="uz">🇺🇿 Uzbek</option>
              <option value="en">🇬🇧 English</option>
            </select>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
