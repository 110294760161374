import React from 'react';
import { useTranslation } from 'react-i18next';

const Home = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="flex flex-col h-screen">

      {/* Main Content */}
      <main className="flex-grow flex flex-col items-center justify-center bg-gray-50">
        <section className="text-center">
          <h2 className="text-4xl font-bold text-blue-600 mb-4">{t('revolutionize_your_flyer_campaigns')}</h2>
          <p className="text-lg text-gray-700 mb-6">{t('effortless_printing_promotion_for_your_business')}</p>
          <a
            href="/register"
            className="bg-blue-600 text-white px-6 py-3 rounded-md shadow-md hover:bg-blue-700 transition"
          >
            {t('get_started')}
          </a>
        </section>
        <section className="container mx-auto mt-12 px-4 text-center">
          <h3 className="text-2xl font-bold mb-6">{t('how_it_works')}</h3>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
            <div className="p-6 bg-white shadow rounded-md">
              <h4 className="text-xl font-semibold mb-3">{t('for_advertisers')}</h4>
              <p>{t('create_flyer_campaigns_and_manage_them_effortlessly')}</p>
            </div>
            <div className="p-6 bg-white shadow rounded-md">
              <h4 className="text-xl font-semibold mb-3">{t('for_printers')}</h4>
              <p>{t('claim_and_print_flyer_requests_with_ease')}</p>
            </div>
            <div className="p-6 bg-white shadow rounded-md">
              <h4 className="text-xl font-semibold mb-3">{t('for_promoters')}</h4>
              <p>{t('distribute_flyers_to_the_target_audience_and_get_rewarded')}</p>
            </div>
          </div>
        </section>
      </main>

      {/* Footer */}
      <footer className="bg-gray-800 text-white py-4">
        <div className="container mx-auto text-center">
          <p>&copy; 2024 Jarchi. {t('all_rights_reserved')}</p>
          <p className="text-sm">
            <a href="/about" className="hover:underline">{t('about')}</a> | 
            <a href="/contact" className="hover:underline">{t('contact')}</a> | 
            <a href="/privacy" className="hover:underline">{t('privacy_policy')}</a>
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Home;
