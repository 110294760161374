import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Polygon, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import { Icon } from 'leaflet';
import { useTranslation } from 'react-i18next';

const RequestForm = ({
    onSubmit,
    editingRequest,
    initialData = { title: '', description: '', quantity: '', flyerImage: null, mapType: 'spot', mapData: null, enableDistribution: true },
}) => {
    const { t, i18n } = useTranslation();
    const [formData, setFormData] = useState(initialData);
    const [selectedFile, setSelectedFile] = useState(null);
    const [mapType, setMapType] = useState(initialData.mapType || 'spot');
    const [mapData, setMapData] = useState(initialData.mapData || null);
    const [enableDistribution, setEnableDistribution] = useState(initialData.enableDistribution || true);

    useEffect(() => {
        if (editingRequest) {
            setFormData({
                title: editingRequest.title,
                description: editingRequest.description,
                quantity: editingRequest.quantity,
            });
            setMapType(editingRequest.area ? 'area' : 'spot');
            setMapData(editingRequest.area || editingRequest.location || null);
            setEnableDistribution(editingRequest.enableDistribution || false);
        }
    }, [editingRequest]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleDistributionChange = (e) => {
        const value = e.target.value === 'yes';
        setEnableDistribution(value);
        if (!value) setMapData(null); // Reset map data when disabled
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const submissionData = new FormData();
        submissionData.append('title', formData.title);
        submissionData.append('description', formData.description);
        submissionData.append('quantity', formData.quantity);
        if (selectedFile) {
            submissionData.append('flyerImage', selectedFile);
        }
        submissionData.append('enableDistribution', enableDistribution);
        submissionData.append('mapType', mapType);
        if (mapData) {
            submissionData.append('mapData', JSON.stringify(mapData));
        }

        onSubmit(submissionData);
    };

    const MapClickHandler = () => {
        useMapEvents({
            click: (e) => {
                if (mapType === 'spot') {
                    setMapData([e.latlng.lat, e.latlng.lng]);
                } else if (mapType === 'area') {
                    setMapData((prev) => [...(prev || []), [e.latlng.lat, e.latlng.lng]]);
                }
            },
        });
        return null;
    };

    return (
        <form onSubmit={handleSubmit} className="mb-8" encType="multipart/form-data">
            {/* Title */}
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">{t('request_title')}</label>
                <input
                    type="text"
                    name="title"
                    value={formData.title}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 border rounded"
                    required
                />
            </div>

            {/* Description */}
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">{t('request_description')}</label>
                <textarea
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 border rounded"
                    required
                />
            </div>

            {/* Quantity */}
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">{t('request_quantity')}</label>
                <input
                    type="number"
                    name="quantity"
                    value={formData.quantity}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 border rounded"
                    required
                />
            </div>

            {/* Flyer Upload */}
            <div className="mb-6">
                <label className="block text-gray-700 text-sm font-bold mb-2">{t('upload_flyer_design')}</label>
                <input type="file" onChange={handleFileChange} className="w-full" accept="image/*" />
            </div>

            {/* Distribution Option */}
            <div className="mb-6">
                <p className="text-gray-700 text-lg font-bold mb-2">{t('request_flyer_distribution')}</p>
                <div className="flex items-center space-x-6">
                    <label className="inline-flex items-center cursor-pointer">
                        <input
                            type="radio"
                            name="enableDistribution"
                            value="yes"
                            checked={enableDistribution}
                            onChange={handleDistributionChange}
                            className="h-6 w-6 text-blue-500"
                        />
                        <span className="ml-2 text-lg font-medium">{t('yes_distribute_with_promoters')}</span>
                    </label>
                    <label className="inline-flex items-center cursor-pointer">
                        <input
                            type="radio"
                            name="enableDistribution"
                            value="no"
                            checked={!enableDistribution}
                            onChange={handleDistributionChange}
                            className="h-6 w-6 text-red-500"
                        />
                        <span className="ml-2 text-lg font-medium">{t('no_print_only')}</span>
                    </label>
                </div>
            </div>

            {/* Map - Conditional */}
            {enableDistribution && (
                <div className="mb-6">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="mapType">
                {t('distribution_location_type')}
                </label>
                <select
                    id="mapType"
                    value={mapType}
                    onChange={(e) => setMapType(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                    <option value="spot">{t('single_spot')}</option>
                    <option value="area">{t('area')}</option>
                </select>
                    <label className="block text-gray-700 text-sm font-bold mb-2">{t('distribution_area_spot')}</label>
                    <MapContainer center={[41.2995, 69.2401]} zoom={13} className="h-72">
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                        <MapClickHandler />
                        {mapType === 'spot' && mapData && (
                            <Marker position={mapData} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} />
                        )}
                        {mapType === 'area' && Array.isArray(mapData) && <Polygon positions={mapData} />}
                    </MapContainer>
                </div>
            )}

            {/* Submit Button */}
            <button type="submit" className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700">
                {editingRequest ? t('update_request') : t('submit_request')}
            </button>
        </form>
    );
};

export default RequestForm;
