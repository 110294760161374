import React, { useEffect, useState } from 'react';
import api from '../services/api';
import RequestForm from '../components/RequestForm';
import { useTranslation } from 'react-i18next';

const AdvertiserDashboard = () => {
    const { t, i18n } = useTranslation();
    const [requests, setRequests] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showForm, setShowForm] = useState(false);
    const [editingRequest, setEditingRequest] = useState(null);
    const [showArchived, setShowArchived] = useState(false); // State for showing archived requests

    useEffect(() => {
        const fetchRequests = async () => {
            try {
                const response = await api.get('/advertisers/requests');
                setRequests(response.data);
                setLoading(false);
            } catch (err) {
                console.error('Failed to load requests:', err);
                setError('Failed to load requests');
                setLoading(false);
            }
        };

        fetchRequests();
    }, []);

    const handleCreateOrEditRequest = async (formData) => {
        try {
            let response;
            if (editingRequest) {
                response = await api.patch(`/advertisers/requests/${editingRequest.id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            } else {
                response = await api.post('/advertisers/requests', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            }

            if (editingRequest) {
                setRequests(requests.map((req) => (req.id === editingRequest.id ? response.data : req)));
            } else {
                setRequests([...requests, response.data]);
            }

            setShowForm(false);
            setEditingRequest(null);
        } catch (err) {
            console.error('Failed to create or update request:', err);
            setError('Failed to create or update request');
        }
    };

    const handleEditClick = (request) => {
        setEditingRequest(request);
        setShowForm(true);
    };

    const handleArchiveRequest = async (requestId) => {
        try {
            await api.patch(`/advertisers/requests/${requestId}/archive`);
            setRequests(requests.map((req) =>
                req.id === requestId ? { ...req, archived: true } : req
            ));
        } catch (err) {
            console.error('Failed to archive request:', err);
        }
    };

    const filteredRequests = requests.filter((req) => showArchived ? req.archived : !req.archived);

    return (
        <div className="container mx-auto p-8">
            <h1 className="text-2xl font-bold mb-6">{t('advertiser_dashboard')}</h1>

            <button
                onClick={() => {
                    setShowForm(!showForm);
                    setEditingRequest(null);
                }}
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 mb-4"
            >
                {showForm ? t('cancel') : t('create_request')}
            </button>

            <button
                onClick={() => setShowArchived(!showArchived)}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mb-4 ml-2"
            >
                {showArchived ? t('hide_archived_requests') : t('show_archived_requests')}
            </button>

            {showForm && (
                <RequestForm
                    onSubmit={handleCreateOrEditRequest}
                    editingRequest={editingRequest}
                />
            )}

            <h2 className="text-xl mb-4">{showArchived ? t('archived_requests') : t('your_requests')}</h2>
            {loading && <p>{t('loading')}...</p>}
            {error && <p className="text-red-500">{error}</p>}

            {filteredRequests.length > 0 ? (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {filteredRequests.map((request, index) => (
                        <div key={index} className="bg-white shadow-md rounded p-4">
                            <h3 className="text-lg font-semibold">{request.title}</h3>
                            <p className="text-gray-700">{request.description}</p>
                            <p className="text-sm text-gray-500">{t('quantity')}: {request.quantity}</p>
                            <button
                                onClick={() => handleEditClick(request)}
                                className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600 mt-2"
                            >
                                {t('edit')}
                            </button>
                            {!request.archived && (
                                <button
                                    onClick={() => handleArchiveRequest(request.id)}
                                    className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 mt-2 ml-2"
                                >
                                    {t('archive')}
                                </button>
                            )}
                        </div>
                    ))}
                </div>
            ) : (
                !loading && <p>No {showArchived ? 'archived' : ''} requests available.</p>
            )}
        </div>
    );
};

export default AdvertiserDashboard;
